export const GF_DARK_BLUE = '#172352';
export const GF_CORAL = '#FF666E';
export const GF_SAND = '#F8F1EC';
export const GF_LIGHT_BLUE = '#5B6282';
export const GF_PERIWINKLE = '#D3D3E9';
export const GF_DARK_SAND = '#EEE1D8';
export const GF_GREY = '#BEBAB7';

export const ADMIMN_PERMISSIONS = [
  'admin-user-management',
  'admin-ticket-management',
  'admin-kpi',
  'admin-edit-shopping-center',
  'admin-card-stock',
];
export const ENTER_KEY = 'Enter';
