import React, { useEffect } from 'react';
import SelectField from './SelectField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from '../../actions/UsersListAction';

export default function SelectUserField({ isDisabled, value, onChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingUsersList, receivedUsersList } = useSelector(
    (state) => state.usersList,
  );
  const { appCount } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (appCount === 0) {
      dispatch(getUsersList());
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center   py-1">{t('User')}: </div>{' '}
      <SelectField
        id="userSelectDropDown"
        isDisabled={isDisabled || loadingUsersList}
        placeholder={false}
        options={receivedUsersList}
        isSearchable={true}
        value={value}
        getOptionLabel={(option) => `${option.label}`}
        onChange={onChange}
      />
    </div>
  );
}
