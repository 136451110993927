/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '@simpleview/react-phone-input-2/lib/bootstrap.css';
import { useLocation } from 'react-router-dom';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../Form';
import Button from '../../../UIComponents/Button';
import TokenField from '../../../../components/Input/TokenField';
import TransferReasonModal from './TransferReasonModal';
import { isNumeric } from '../../../../utils/Helper';
import { issuingAPI } from '../../../../services/Axios';
import Container from '../../../UIComponents/Container';
import FailAlert from '../../../Alert/FailAlert';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import cardEnum from '../../../../enums/card.enum';
import Money from '../../../Money';

const CardTooltip = ({ data, checkLoadable = false }) => {
  const { t } = useTranslation();
  const { tagCurrency } = useSelector((state) => state.tag);

  const statusColor = useMemo(() => {
    if (data?.status === 'terminate') {
      return 'text-kadozRed';
    }
    if (data?.status === 'inactive') {
      return 'text-kadozGoldYellow';
    }
    return 'text-kadozGreen';
  }, [data]);

  if (!data) {
    return <></>;
  }

  if (data.loading) {
    return (
      <div className="flex items-center">
        <ButtonLoading color={'#4A5568'} />
        <span>{t('Getting-card-information')}...</span>
      </div>
    );
  }

  if (data.errors) {
    return <FailAlert message={`${data.errors}`} />;
  }

  return (
    <div className={'border p-3 rounded-md'}>
      <div>
        {t('Available-Balance')}:
        <span className="font-MulishBlack text-gfLightBlue">
          {' '}
          <Money value={data.balance_available} currency={tagCurrency} />
        </span>
      </div>
      <div>
        {t('Expiry-date')}: <span>{data.expiration_date}</span>
      </div>
      {checkLoadable && (
        <div>
          {t('Is-card-loadable')}:{' '}
          <span>{t(data.is_loadable ? 'yes' : 'no')}</span>
        </div>
      )}
      <div>
        {t('Status')}:{' '}
        <span className={statusColor}>
          {String(data.status).toLocaleUpperCase()}
        </span>
      </div>
    </div>
  );
};

export default function CardToCard() {
  const { t } = useTranslation();
  const onSubmit = () => {
    setIsModalOpen(true);
  };
  const { selectedTag } = useSelector((state) => state.tag);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      toCard: '',
      fromCard: '',
    },
  });

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const searchParams = useQuery();
  const fromToken = searchParams.get('fromToken');
  const toToken = searchParams.get('toToken');

  useEffect(() => {
    if (fromToken) {
      methods.setValue('fromCard', fromToken, { shouldValidate: true });
    }
  }, [fromToken]);

  useEffect(() => {
    if (toToken) {
      methods.setValue('toCard', toToken, { shouldValidate: true });
    }
  }, [toToken]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fromTokenInfos, setFromTokenInfos] = useState(null);
  const [toTokenInfos, setToTokenInfos] = useState(null);

  const tokenChangeHandler = async (
    token,
    setValue,
    postDataCheckCallback = () => true,
  ) => {
    setValue(null);
    if (isNumeric(token) && token.length === cardEnum.TOKEN_LENGTH) {
      try {
        setValue({ loading: true });
        const resp = await getTokenInfo(token);
        setValue(resp.data);
        return postDataCheckCallback(resp.data);
      } catch (e) {
        setValue({ errors: t(e) });
        return false;
      }
    }
    return false;
  };

  const handleOnClose = (refreshPage) => {
    if (refreshPage) {
      methods.setValue('fromCard', '');
      methods.setValue('toCard', '');
      setFromTokenInfos(null);
      setToTokenInfos(null);
    }
    setIsModalOpen(false);
  };

  const getTokenInfo = useCallback(
    (token) => {
      return issuingAPI.get(`/tag/${selectedTag.id}/card/${token}`);
    },
    [selectedTag],
  );

  const getTokenNewExpiryDate = useCallback(
    ({ token }) => {
      return issuingAPI.get(`/tag/${selectedTag.id}/replacements/${token}`);
    },
    [selectedTag],
  );

  return (
    <Container title={t('card-replacements.tab')}>
      <>
        <Form {...methods}>
          <form className={'flex'} onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="md:max-w-2xl">
              <div className="flex space-x-4">
                <FormField
                  name={`fromCard`}
                  defaultValue={''}
                  rules={{
                    required: true,
                    validate: async (value) =>
                      tokenChangeHandler(
                        value,
                        setFromTokenInfos,
                        async (respData) => {
                          try {
                            const { data } =
                              await getTokenNewExpiryDate(respData);
                            setFromTokenInfos({
                              ...respData,
                              tokenNewExpiryDate: data.data?.expiration_date,
                            });
                            return true;
                          } catch (e) {
                            return false;
                          }
                        },
                      ),
                  }}
                  render={(formField, fieldState) => {
                    return (
                      <FormItem className={'w-64'}>
                        <FormControl>
                          <>
                            <TokenField
                              {...formField}
                              invalid={fieldState.invalid}
                              valid={
                                methods.formState.touched.token &&
                                !fieldState.invalid
                              }
                              placeholder={t('Card to debit')}
                            />
                            <CardTooltip data={fromTokenInfos} />
                          </>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name={`toCard`}
                  defaultValue={''}
                  rules={{
                    required: true,
                    validate: async (value) =>
                      tokenChangeHandler(value, setToTokenInfos, (card) => {
                        return card.is_loadable;
                      }),
                  }}
                  render={(formField, fieldState) => {
                    return (
                      <FormItem className={'w-64'}>
                        <FormControl>
                          <>
                            <TokenField
                              {...formField}
                              invalid={fieldState.invalid}
                              valid={
                                methods.formState.touched.token &&
                                !fieldState.invalid
                              }
                              placeholder={t('Card to credit')}
                            />
                            <CardTooltip
                              data={toTokenInfos}
                              checkLoadable={true}
                            />
                          </>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <div>
                  <Button
                    id={'cardInfoButton'}
                    label={t('Proceed')}
                    classAddsName={`${!methods.formState.isValid ? 'bg-opacity-50 pointer-events-none' : ''}`}
                  />
                </div>
              </div>
            </div>
          </form>
        </Form>
        <TransferReasonModal
          onClose={(refreshPage = false) => {
            handleOnClose(refreshPage);
          }}
          isOpen={isModalOpen}
          data={{
            from: fromTokenInfos,
            to: toTokenInfos,
          }}
        />
      </>
    </Container>
  );
}
