import React, { forwardRef } from 'react';
import Input from './index';
import { hasFloatMoreThanMaxDecimal } from '../../utils/Helper';

const AmountField = forwardRef(function (
  { max = undefined, min = 0, value, onBlur, onChange, placeholder },
  ref,
) {
  const onInput = (e) => {
    const val = e.target.value;
    if (hasFloatMoreThanMaxDecimal(val)) {
      onChange(val.slice(0, -1));
      return;
    }

    onChange(val);
  };

  return (
    <Input
      type="number"
      ref={ref}
      value={value}
      onChange={onInput}
      placeholder={placeholder}
    />
  );
});

export default AmountField;
