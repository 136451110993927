import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import CloseIcon from '../../../assets/svg/CloseIcon';
import CheckBox from './CheckBox';

ReactModal.setAppElement('#root');

export default function ViewUser({ isOpen, closeHandler, userData }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm();
  const { user, editUserPermissions } = useSelector((state) => state.auth);
  const { receivedUserPermissions, receivedUserOrganizationPermissions } =
    useSelector((state) => state.userManagement);

  const onSubmit = (data) => {};

  const handleOnChange = (index) => {};

  const checkLoggedInUserPermission = (id) => {
    let hasPermission = false;
    if (user.super_admin) {
      hasPermission = true;
    } else {
      user.permissions.forEach((permission) => {
        if (permission === id) {
          hasPermission = true;
        }
      });
    }

    return false;
  };

  const checkHiddenPermissions = (permission) => {
    const permissions =
      window?.appConfig.REACT_APP_HIDDEN_PERMISSIONS.split(',');
    if (permissions.includes(permission.guard_name)) {
      console.log(permission.guard_name);
    }
    return !permissions.includes(permission.guard_name);
  };

  const checkGrantedPermissions = (id) => {
    let hasPermission = false;
    if (receivedUserOrganizationPermissions !== null) {
      receivedUserOrganizationPermissions.forEach((permission, index) => {
        if (permission !== null) {
          if (permission.guard_name === id) {
            hasPermission = true;
          }
          // console.log(permission[index]);
        }
      });
    }

    return hasPermission;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
        <div>
          <div className="flex justify-between items-center mb-5">
            <div className="font-MulishBold">{t('User')}</div>
            <button onClick={() => closeHandler(!isOpen)}>
              <CloseIcon />
            </button>
          </div>

          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-5">
              <div className="flex flex-col">
                <div className="flex justify-start font-MulishBold">
                  {t('ID')}
                </div>

                <div className="w-full border border-gfPeriwinkle rounded px-4 py-2 flex justify-start items-center  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle">
                  {userData.id}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex justify-start font-MulishBold">
                  {t('Name')}
                </div>

                <div className="w-full border border-gfPeriwinkle rounded px-4 py-2 flex justify-start items-center  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle">
                  {`${userData.first_name} ${userData.last_name}`}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex justify-start font-MulishBold">
                  {t('Email-address')}
                </div>

                <div className="w-full border border-gfPeriwinkle rounded px-4 py-2 flex justify-start items-center  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle">
                  {userData.email}
                </div>
              </div>
            </div>

            <div className="py-5">
              {editUserPermissions.map((per) => (
                <div
                  key={per.name}
                  className="flex flex-col justify-start items-start"
                >
                  <div className="px-4 font-MulishBold">
                    {t(String(per.name).replace(/\s+/g, '-'))}
                  </div>
                  <ul className="mb-5">
                    {per.list.map(
                      (item) =>
                        checkHiddenPermissions(item) && (
                          <CheckBox
                            handleCheckChieldElement={handleOnChange(item.id)}
                            disabled={
                              !checkLoggedInUserPermission(item.guard_name)
                            }
                            isChecked={checkGrantedPermissions(item.guard_name)}
                            {...item}
                          />
                        ),
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}
