import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import LanguageReducer from './LanguageReducer';
import AuthReducer from './AuthReducer';
import LoadCardReducer from './LoadCardReducer';
import CardOrdersReducer from './CardOrdersReducer';
import MVCReducer from './MVCReducer';
import CardTransferReducer from './CardTransferReducer';
import CardInformationReducer from './CardInformationReducer';
import TagReducer from './TagReducer';
import TerminalReducer from './TerminalReducer';
import ProductReportReducer from './reports/ProductReportReducer';
import ExpiryReportReducer from './reports/ExpiryReportReducer';
import DeliveryAddressReducer from './DeliveryAddressReducer';
import CountriesReducer from './CountriesReducer';
import ReportByChannelReducer from './reports/ReportByChannelReducer';
import DailyReportReducer from './reports/DailyReportReducer';
import ManageSalesReducer from './reports/ManageSalesReducer';
import ManageOrdersReducer from './orders/ManageOrdersReducer';
import SalesByMerchantReducer from './reports/SalesByMerchantReducer';
import FeesReportReducer from './reports/FeesReportReducer';
import ActivatePromoCardsReducer from './ActivatePromoCardsReducer';
import ShopListReducer from './ShopListReducer';
import ShoppingCenterBalanceReducer from './ShoppingCenterBalanceReducer';
import NotificationReducer from './NotificationReducer';
import MVCToMVCTransferReducer from './MVCToMVCTransferReducer';
import SalesSummaryReducer from './SalesSummaryReducer';
import WeeklyBreakdownReducer from './WeeklyBreakdownReducer';
import CommonReducer from './CommonReducer';
import ManageShopReducer from './ManageShopReducer';
import AdminReducer from './AdminReducer';
import UserManagementReducer from './UserManagementReducer';
import CardStockReducer from './CardStockReducer';
import DigitisationReducer from './reports/DigitisationReducer';
import BlockedCardsReducer from './BlockedCardsReducer';
import ManageSalesB2BReducer from './ManageSalesB2BReducer';
import MVCLoadReducer from './MVCLoadReducer';
import ManagementPerformanceReducer from './reports/ManagementPerformanceReducer';
import TransactionOverviewReducer from './TransactionOverviewReducer';
import CardUsageAtMerchantReducer from './reports/CardUsageAtMerchantReducer';
import PlaceB2BOrderReducer from './PlaceB2BOrderReducer';
import MyPortalCardsLoadReducer from './reports/MyPortalCardsLoadReducer';
import UsersListReducer from './UsersListReducer';
import DesksListReducer from './DesksListReducer';

const RootReducer = combineReducers({
  language: LanguageReducer,
  auth: AuthReducer,
  loadCard: LoadCardReducer,
  cardOrders: CardOrdersReducer,
  mvc: MVCReducer,
  mvcLoad: MVCLoadReducer,
  cardTransfer: CardTransferReducer,
  cardInformation: CardInformationReducer,
  tag: TagReducer,
  terminal: TerminalReducer,
  productReport: ProductReportReducer,
  expiryReport: ExpiryReportReducer,
  form: reduxFormReducer,
  deliveryAddress: DeliveryAddressReducer,
  countries: CountriesReducer,
  reportByChannel: ReportByChannelReducer,
  dailyReport: DailyReportReducer,
  manageSales: ManageSalesReducer,
  manageOrders: ManageOrdersReducer,
  salesByMerchant: SalesByMerchantReducer,
  feesReport: FeesReportReducer,
  activatePromoCards: ActivatePromoCardsReducer,
  shopList: ShopListReducer,
  shoppingCenterBalance: ShoppingCenterBalanceReducer,
  notification: NotificationReducer,
  mvc_mcvTransfer: MVCToMVCTransferReducer,
  salesSummary: SalesSummaryReducer,
  weeklyBreakdown: WeeklyBreakdownReducer,
  commonReducer: CommonReducer,
  manageShops: ManageShopReducer,
  admin: AdminReducer,
  userManagement: UserManagementReducer,
  cardStock: CardStockReducer,
  tokenizationReport: DigitisationReducer,
  blockedCards: BlockedCardsReducer,
  manageSalesB2B: ManageSalesB2BReducer,
  performanceReport: ManagementPerformanceReducer,
  transactionOverview: TransactionOverviewReducer,
  cardUsageAtMerchant: CardUsageAtMerchantReducer,
  placeB2BOrder: PlaceB2BOrderReducer,
  reportingMyPortalCardsLoad: MyPortalCardsLoadReducer,
  usersList: UsersListReducer,
  desksList: DesksListReducer,
});

export default RootReducer;
