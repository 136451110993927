import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  checkAdminPermissions,
  checkLoggedInUserPermissions,
} from '../../../../utils/Helper';
import { RESET_APP_COUNT } from '../../../../actions/CommonActions';
import {
  CHANGE_SEARCH_TYPE,
  getCardsListByPhoneNumber,
  RESET_CARD_INFO,
  RESET_SEARCH_TYPE,
} from '../../../../actions/CardInformationActions';

export default function CardInformationDetails({
  isBackToList,
  openModal,
  setSelectedCard,
  searchCardTypesList,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cardInformation, cardPhoneNumber } = useSelector(
    (state) => state.cardInformation,
  );
  const { isSuperAdmin, addUserPermissions } = useSelector(
    (state) => state.auth,
  );
  const { tagCurrency } = useSelector((state) => state.tag);

  const linkOnClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
    dispatch({ type: RESET_SEARCH_TYPE });
  };

  const onClickBackToList = (e) => {
    dispatch({ type: RESET_CARD_INFO });
    dispatch({ type: CHANGE_SEARCH_TYPE, payload: searchCardTypesList[1] });
    dispatch(getCardsListByPhoneNumber(cardPhoneNumber));
    setSelectedCard(false);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 border-gfPeriwinkle border rounded shadow-lg p-5 md:py-10 md:px-20">
        <div className="w-1/2 space-y-5">
          {isBackToList && (
            <div className="flex flex-row space-x-2 items-">
              <span
                onClick={onClickBackToList}
                className="underline cursor-pointer"
              >
                {t('Back to list')}{' '}
              </span>
            </div>
          )}
          <div className={'flex justify-between'}>
            <div className="flex flex-row space-x-2">
              <div className=" text-xl font-MulishBold">
                {t('Card-balance')}:
              </div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold text-xl">
                <CurrencyFormat
                  data-testid="cardBalance"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={cardInformation.balance_available}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                <span
                  data-testid="cardBalanceCurrency"
                  className="ml-2"
                >{` ${tagCurrency}`}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className=" text-xl font-MulishBold">
              {t('Is-card-loadable ')}:
            </div>
            <div className="flex justify-start text-giftifyDarkBlue  text-xl">
              {cardInformation.is_loadable ? (
                <span
                  data-testid="isCardLoadable"
                  className=" text-kadozGreen font-MulishBold uppercase"
                >
                  {t('Yes')}
                </span>
              ) : (
                <span
                  data-testid="isCardLoadable"
                  className=" text-giftifyDarkPink font-MulishBold uppercase"
                >
                  {t('No')}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className=" text-xl font-MulishBold">{t('Expiry-date')}:</div>
            <div className="flex justify-start text-gfLightBlue font-MulishBold text-xl">
              <span data-testid="expiryDate" className="">
                {cardInformation.expiration_date}
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className=" text-xl font-MulishBold">
              {t('Status')}:{' '}
              <span
                data-testid="status"
                className="bg-gfGrey font-MulishBold px-2"
              >
                {String(cardInformation.status).toLocaleUpperCase()}
              </span>
            </div>
          </div>

          {cardInformation.description && (
            <div className="flex flex-row space-x-2">
              <div className="">
                <div
                  data-testid="reasonForBlockUnblock"
                  className="text-xl font-MulishBold "
                >
                  {cardInformation.status === 'blocked'
                    ? t('reason-for-block')
                    : t('reason-for-unblock')}
                </div>
                <div className="flex flex-col text-base">
                  <div className="flex flex-row">
                    <div className="ml-4 font-MulishBold ">{t('User')}</div> :{' '}
                    <div data-testid="user" className="ml-2">
                      {cardInformation.description.u_email}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="ml-4 font-MulishBold ">{t('reason')}</div> :{' '}
                    <div data-testid="reason" className="ml-2">
                      {cardInformation.description.desc}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isBackToList &&
            (cardInformation?.card_holder?.first_name ||
              cardInformation?.card_holder?.last_name) && (
              <div className="flex flex-row space-x-2">
                <div className=" text-xl font-MulishBold">
                  {t('card_information.search.headers.card_holder_name')}:
                </div>
                <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                  <span data-testid="cardHolderName">{`${cardInformation.card_holder.first_name} ${cardInformation.card_holder.last_name}`}</span>
                </div>
              </div>
            )}
          {isBackToList && cardInformation?.card_holder?.phone_number && (
            <div className="flex flex-row space-x-2">
              <div className=" text-xl font-MulishBold">
                {t('card_holder.phone_number')}:
              </div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                <span data-testid="cardHolderPhoneNumber">{`${cardInformation.card_holder.phone_number}`}</span>
              </div>
            </div>
          )}
          {isSuperAdmin && cardInformation.payment_method.is_temp && (
            <div className="flex flex-row space-x-2">
              <div className=" text-xl font-MulishBold">
                {t('Is-Temporary')}:
              </div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                <span
                  data-testid="isTemporary"
                  className="uppercase"
                >{`${cardInformation.payment_method.is_temp === true ? t('Yes') : t('No')}`}</span>
              </div>
            </div>
          )}
          {isSuperAdmin && cardInformation.payment_method.is_correction_mvc && (
            <div className="flex flex-row space-x-2">
              <div className=" text-xl font-MulishBold">
                {t('Is-Correction-MVC')}:
              </div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                <span
                  data-testid="isCorrectionMvc"
                  className="uppercase"
                >{`${cardInformation.payment_method.is_correction_mvc === true ? t('Yes') : t('No')}`}</span>
              </div>
            </div>
          )}

          {cardInformation.transferred_from && true && (
            <div className="flex flex-row space-x-2">
              <div className="text-xl font-MulishBold">
                {t('transfer-from')}:
              </div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                {/* <span className="">{ `${cardInformation.transferred_from}` }</span> */}
                <Link
                  data-testid="transferFromToken"
                  onClick={linkOnClickHandler}
                  className="underline"
                  to={`/gift-cards/card-information?token=${cardInformation.transferred_from}`}
                >
                  {cardInformation.transferred_from}
                </Link>
              </div>
            </div>
          )}

          {cardInformation.transferred_to && (
            <div className="flex flex-row space-x-2">
              <div className="text-xl font-MulishBold">{t('transfer-to')}:</div>
              <div className="flex justify-start text-gfLightBlue font-MulishBold  text-xl">
                {/* <span className="">{ `${cardInformation.transferred_to}` }</span> */}
                <Link
                  data-testid="transferToToken"
                  onClick={linkOnClickHandler}
                  className="underline"
                  to={`/gift-cards/card-information?token=${cardInformation.transferred_to}`}
                >
                  {cardInformation.transferred_to}
                </Link>
              </div>
            </div>
          )}

          {((cardInformation.is_mvc !== '1' &&
            cardInformation.status === 'active' &&
            cardInformation.balance_available > 0) ||
            cardInformation.status === 'blocked') && (
            <div>
              {checkAdminPermissions(
                'block-unblock-card',
                addUserPermissions,
              ) && (
                <div>
                  {cardInformation.status === 'blocked' ? (
                    <button
                      data-testid="unblockButton"
                      id="unblockButton"
                      onClick={openModal}
                      className="py-2 px-4 bg-gfPeriwinkle  focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                    >
                      {t('Unblock-card')}
                    </button>
                  ) : (
                    <button
                      data-testid="blockButton"
                      id="blockButton"
                      onClick={openModal}
                      className="py-2 px-4 bg-gfCoral  focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                    >
                      {t('Block-card')}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={'flex justify-between'}>
          {cardInformation.fields && cardInformation.fields.length !== 0 && (
            <div className="flex flex-col space-y-5">
              <div className="font-MulishBold">{t('User-Information')}</div>
              {cardInformation.fields.map((field) => (
                <div className="flex flex-row">
                  <div>{`${field.field_name} :`}</div>
                  <div
                    data-testid={`field${field.field_name}`}
                  >{`${field.value}`}</div>
                </div>
              ))}
            </div>
          )}
          {checkLoggedInUserPermissions('card-to-card-transfer-new') &&
            (cardInformation.status === 'loaded' ||
              cardInformation.status === 'active') && (
              <div className={'flex justify-end'}>
                <div>
                  <Link
                    className="replaceCard font-bold bg-gfCoral py-2 px-4 rounded-md"
                    to={`/gift-cards/card-transfer-new?fromToken=${cardInformation.token}`}
                  >
                    {t('Replace')}
                  </Link>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
