import {
  ERROR_USERS_LIST,
  LOADING_USERS_LIST,
  RECEIVED_USERS_LIST,
  RESET_USERS_LIST,
} from '../actions/UsersListAction';
import i18n from 'i18next';

const initialState = {
  loadingUsersList: false,
  receivedUsersList: null,
  errorUsersList: null,
  defaultUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USERS_LIST:
      return {
        ...state,
        loadingUsersList: true,
        receivedUsersList: null,
        errorUsersList: null,
      };

    case RECEIVED_USERS_LIST:
      let tempPayload = action.payload;
      let temp;
      if (tempPayload.length !== 0) {
        temp = [
          { id: null, label: i18n.t('All'), firstName: '', lastName: '' },
          ...tempPayload,
        ];
        tempPayload.forEach((item) => {
          item.label = `${item.firstName} ${item.lastName}`;
        });
      }
      return {
        ...state,
        loadingUsersList: false,
        receivedUsersList: temp,
        errorUsersList: null,
        defaultUser: temp[0],
      };

    case ERROR_USERS_LIST:
      return {
        ...state,
        loadingUsersList: false,
        receivedUsersList: null,
        errorUsersList: action.payload,
      };

    case RESET_USERS_LIST:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
