import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import OrderStatusEnum from '../../../enums/orderStatus.enum';
import OrderPurchaserDetails from '../../orders/OrderPurchaserDetails';
import {
  MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID,
  CHANGING_ORDER_STATUS,
  CHANGED_ORDER_STATUS,
  ERROR_CHANGING_ORDER_STATUS,
  RESET_CHANGING_ORDER_STATUS,
  ORDER_PREPARE_RESET,
} from '../../../actions/ManageOrdersActions';
import { issuingAPI } from '../../../services/Axios';
import DownLoadIcon from '../../../assets/svg/DownLoadIcon';
import {
  exportExcelFile,
  generateOperationID,
  checkLoggedInUserPermissions,
} from '../../../utils/Helper';
import SuccessAlert from '../../../components/Alert/SuccessAlert';
import FailAlert from '../../../components/Alert/FailAlert';
import ValidationErrors from '../../../components/Alert/ValidationErrors';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../utils/Constants';
import { RESET_VALIDATION_ERRORS } from '../../../actions/CommonActions';
import {
  INITIAL_LOAD_CARD,
  RESET_ADD_CARD_FIELDS,
} from '../../../actions/LoadCardActions';
import { ModalSubTitle } from '../../../components/Modal';
import { useOrdersGroupedByAmount } from '../../../hooks/useOrderDetails';
import Money from '../../../components/Money';

export default function ({
  orderDetails,
  setIsPreparing,
  onClose,
  onPrepare,
  contentLabel,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);
  const { changingOrderStatus, changedOrderStatus, errorChangingOrder } =
    useSelector((state) => state.manageOrders);
  const { validationErrors } = useSelector((state) => state.commonReducer);
  const ordersByAmount = useOrdersGroupedByAmount({ order: orderDetails });

  const canClickAndCollectCode = useMemo(
    () => checkLoggedInUserPermissions('download-click-and-collect-codes'),
    [checkLoggedInUserPermissions],
  );
  const canUpdateOrderStatus = useMemo(
    () => checkLoggedInUserPermissions('update-order-status'),
    [checkLoggedInUserPermissions],
  );

  useEffect(() => {
    dispatch({ type: RESET_CHANGING_ORDER_STATUS });
    dispatch({ type: RESET_VALIDATION_ERRORS });
  }, []);

  const downloadClickAndCollectCodes = ({ reference }) => {
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const reportName = `collect_codes_${reference}`;

    issuingAPI
      .get(
        `/tag/${selectedTag.id}/global-order/${reference}/click-and-collect-codes/stream`,
        { responseType: 'arraybuffer', headers },
      )
      .then((response) => {
        exportExcelFile(response.data, reportName);
      });
  };

  const giftCardsDisplay = (orderDetails) => {
    const giftCardsNumberText =
      orderDetails.total_orders > 1
        ? `${orderDetails.total_orders} ${t('gift cards')}`
        : `${orderDetails.total_orders} ${t('gift card')}`;

    const giftCardsPickedUpText = `${orderDetails.total_picked_up_orders} ${t('picked up')}`;

    return `${giftCardsNumberText} (${giftCardsPickedUpText})`;
  };

  const setToPickedUp = (reference) => {
    dispatch({ type: CHANGING_ORDER_STATUS });

    const headers = {
      'operation-id': generateOperationID(),
    };

    issuingAPI
      .put(
        `tag/${selectedTag.id}/global-order/${reference}/status`,
        {
          status: 'picked_up',
        },
        { headers },
      )
      .then((res) => {
        dispatch({ type: CHANGED_ORDER_STATUS, payload: res.data });
        onPrepare();
        setTimeout(() => {
          onClose();
        }, 3000);
      })
      .catch((error) => {
        dispatch({ type: ERROR_CHANGING_ORDER_STATUS, payload: error });
      })
      .finally(() => {
        dispatch({ type: MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID });
      });
  };

  if (!orderDetails) {
    return <></>;
  }

  return (
    <div data-testid={contentLabel}>
      <header className={'relative mb-5'}>
        <div className={'flex flex-col justify-start items-start'}>
          <h1 className={'uppercase font-bold text-md mb-4'}>
            {`${t('Order information')} - ${orderDetails.reference}`}
          </h1>
          {orderDetails.status === OrderStatusEnum.PAID &&
            canClickAndCollectCode && (
              <div>
                <button
                  onClick={() => downloadClickAndCollectCodes(orderDetails)}
                  className={
                    'text-sm bg-gfDarkBlue text-white p-2 rounded flex flex-row gap-2'
                  }
                >
                  {t('Click & collect codes')}
                  <DownLoadIcon />
                </button>
              </div>
            )}
        </div>
      </header>
      <section className={'text-left'}>
        {orderDetails.purchaser && (
          <OrderPurchaserDetails purchaser={orderDetails.purchaser} />
        )}

        {orderDetails.orders && (
          <div className={'mt-6'}>
            <ModalSubTitle>{t('Order-Recap')}</ModalSubTitle>
            <div className={'flex mt-4'}>
              <div className={'font-semibold truncate w-48 flex flex-col'}>
                <span>{t('Total amount')}:</span>
                <span>{t('Summary')}:</span>
              </div>
              <div className={'flex flex-col'}>
                <CurrencyFormat
                  decimalScale={2}
                  suffix={` ${selectedTag.currency}`}
                  fixedDecimalScale={true}
                  value={orderDetails.amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  data-testid={'totalAmount'}
                />
                <p data-testid={'summary'}>{giftCardsDisplay(orderDetails)}</p>
              </div>
            </div>
          </div>
        )}
        {ordersByAmount.length > 0 && (
          <div className={'mt-6'}>
            <ModalSubTitle>{t('Cards')}</ModalSubTitle>
            <div className="flex flex-col mt-4" data-testid={'cards'}>
              {ordersByAmount.map((byAmount) => {
                return (
                  <div key={byAmount.amount}>
                    <Money
                      value={byAmount.amount}
                      currency={selectedTag.currency}
                    />{' '}
                    <span
                      data-testid={'quantity'}
                    >{`(x${byAmount.quantity})`}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {orderDetails.additional_comment && (
          <div className={'mt-6'}>
            <ModalSubTitle>
              {t('manage-orders.additional-comment')}
            </ModalSubTitle>
            <p className="my-4">{orderDetails.additional_comment}</p>
          </div>
        )}
        <div className="my-5">
          {errorChangingOrder && (
            <FailAlert message={errorChangingOrder.message} />
          )}
          {changedOrderStatus !== null && (
            <SuccessAlert message={t('Success')} />
          )}
          {validationErrors && (
            <div className="py-2">
              <ValidationErrors {...validationErrors} />
            </div>
          )}
        </div>
        {orderDetails.status === OrderStatusEnum.PAID &&
        orderDetails.display_status !== OrderStatusEnum.PARTIALLY_PICKED_UP &&
        canUpdateOrderStatus ? (
          <button
            onClick={() => {
              setIsPreparing(true);
              dispatch({ type: ORDER_PREPARE_RESET });
              dispatch({ type: RESET_ADD_CARD_FIELDS });
              dispatch({ type: INITIAL_LOAD_CARD });
            }}
            className={
              'bg-gfCoral block py-2 px-28 rounded text-center mx-auto mt-6 font-semibold'
            }
          >
            {t('Prepare all cards')}
          </button>
        ) : (orderDetails.status === OrderStatusEnum.PARTIALLY_PREPARED ||
            orderDetails.status === OrderStatusEnum.PREPARED) &&
          orderDetails.display_status !== OrderStatusEnum.PICKED_UP ? (
          <button
            onClick={(e) => {
              setToPickedUp(orderDetails.reference);
            }}
            className={
              'bg-gfPeriwinkle flex flex-row py-2 px-28 rounded text-center mx-auto mt-6 font-semibold'
            }
          >
            {changingOrderStatus && <ButtonLoading color={GF_DARK_BLUE} />}
            {t('Pick up cards')}
          </button>
        ) : (
          <button
            onClick={onClose}
            className={
              'bg-gfPeriwinkle block py-2 px-28 uppercase rounded text-center mx-auto mt-6 font-semibold'
            }
          >
            {t('Close')}
          </button>
        )}
      </section>
    </div>
  );
}
