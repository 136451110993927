import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import Select from 'react-select';
import { FormControl, FormField, FormItem, FormMessage } from '../../Form';
import DropDownStyle from '../../Dropdown/Components/DropDownStyle';
import { InputGroup, InputGroupText } from '../../InputGroup';
import AmountField from '../../Input/AmountField';

function PaymentMethodField({ paymentMethod }) {
  const { paymentMethods } = useSelector((state) => state.loadCard);
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();
  const selectedPaymentMethods = useWatch({ name: 'paymentMethod' });

  const options = useMemo(() => {
    const selected = selectedPaymentMethods.map(({ method_id }) => method_id);

    return (paymentMethods || [])
      .map((paymentMethod) => ({
        label: paymentMethod.name,
        value: paymentMethod.id,
      }))
      .filter((option) => !selected.includes(option.value));
  }, [paymentMethods, selectedPaymentMethods]);

  function handleAddPaymentMethod() {
    paymentMethod.append({
      method_id: '',
      amount: 0,
    });
  }

  return (
    <div className="flex flex-col space-y-4">
      {paymentMethod.fields.map((field, index) => {
        return (
          <div key={field.id}>
            {paymentMethods?.length !== 0 && (
              <div
                className="flex flex-wrap space-x-3 items-center"
                key={index}
              >
                <FormField
                  name={`paymentMethod.${index}.method_id`}
                  rules={{ required: t('place-b2b-order.required') }}
                  defaultValue={''}
                  render={(field) => {
                    const selected = (paymentMethods || []).find(
                      (method) => method.id === field.value,
                    );

                    return (
                      <FormItem>
                        <FormControl>
                          <div data-testid={`paymentMethod.${index}.method_id`}>
                            <Select
                              className={'w-48'}
                              styles={DropDownStyle((option) => option)}
                              inputRef={field.ref}
                              onChange={(value) => {
                                field.onChange(value.value);
                              }}
                              value={selected ? { label: selected.name } : null}
                              options={options}
                              isSearchable={true}
                              components={{
                                IndicatorSeparator: null,
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />

                {paymentMethod.fields.length > 1 && (
                  <FormField
                    name={`paymentMethod.${index}.amount`}
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <InputGroup className="w-32">
                          <FormControl>
                            <AmountField
                              {...field}
                              placeholder={`100.00`}
                              className="text-right pr-12"
                            />
                          </FormControl>
                          <InputGroupText position="right">
                            {tagCurrency}
                          </InputGroupText>
                        </InputGroup>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                {paymentMethod.fields.length > 1 && index !== 0 && (
                  <button
                    className={
                      'rounded-md text-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white'
                    }
                    data-testid={`removePaymentMethodButton${index}`}
                    type="button"
                    onClick={() => paymentMethod.remove(index)}
                  >
                    <span className="sr-only">Close panel</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
        );
      })}

      <div className="my-2 w-48">
        <button
          data-testid={`addPaymentMethodButton`}
          onClick={handleAddPaymentMethod}
          className={
            'bg-gfPeriwinkle border border-gfPeriwinkle font-MulishBold text-gfDarkBlue rounded text-sm py-2 px-4 hover:bg-white focus:outline-none h-10'
          }
          type="button"
        >
          {t('place-b2b-order.add-addional-method')}
        </button>
      </div>
    </div>
  );
}

export default PaymentMethodField;
