import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

const DPCustomInput = forwardRef(({ value, onClick, placeholderText }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    readOnly={true}
    placeholder={placeholderText}
    className="border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none"
  />
));

export default function DatePickerField({
  label,
  disabled,
  selected,
  onChange,
  showTimeSelect,
}) {
  const { selectedLanguage } = useSelector((state) => state.language);

  let handleColor = (time) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error';
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center   py-1">{label}:</div>
      <ReactDatePicker
        showMonthDropdown
        showTimeSelect={showTimeSelect}
        showYearDropdown
        disabled={disabled}
        dateFormat="Pp"
        customInput={<DPCustomInput />}
        selected={selected}
        onChange={onChange}
        locale={selectedLanguage.value}
        timeClassName={handleColor}
      />
    </div>
  );
}
