import * as React from 'react';
import { useSelector } from 'react-redux';
import { cn } from '../../utils';

const DefaultReceipt = React.forwardRef(({ ...props }, ref) => {
  const { selectedTag } = useSelector((state) => state.tag);

  return (
    <div
      ref={ref}
      style={{
        maxWidth: '165px',
        margin: 'auto',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
        }}
      >
        <img
          style={{ maxWidth: '100px' }}
          src={`${window?.appConfig.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
          alt="logo"
        />
      </div>

      <div
        style={{
          textAlign: 'left',
          fontSize: '16px',
          paddingTop: '30px',
          paddingBottom: '30px',
          fontWeight: 'bolder',
        }}
      >
        {selectedTag.name}
      </div>

      {props.children}

      <div style={{ textAlign: 'center', padding: '30px 0px' }}>
        <div>{selectedTag.name}</div>
        <div className="text-center">{`${selectedTag.address}, ${selectedTag.zip}, ${selectedTag.city}, ${selectedTag.country_code}`}</div>
        <div style={{ width: '155px', wordBreak: 'break-all' }}>
          {selectedTag.support_email}
        </div>
        <div>{selectedTag.support_phone}</div>
        <div>{selectedTag.website}</div>
      </div>

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {selectedTag.ticket_footer}
      </div>
    </div>
  );
});

const ReceiptTitle = React.forwardRef(({ title, ...props }, ref) => {
  return (
    <span
      style={{
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        marginRight: '4px',
      }}
      ref={ref}
      {...props}
    >{`${title}: `}</span>
  );
});

const ReceiptField = React.forwardRef(
  ({ className, name, value, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex items-center w-full', className)}
        {...props}
      >
        <span style={{ fontWeight: 'bold' }}>{`${name}: `}</span>
        <span style={{ marginLeft: '4px' }}>{value}</span>
      </div>
    );
  },
);

export { DefaultReceipt, ReceiptTitle, ReceiptField };
